import { ImportationCategory } from '../../../../interfaces';

export const GENERAL_INFO_FORM: ImportationCategory = {
  name: 'generalInfo',
  rules: [
    {
      name: 'account',
      required: true
    },
    {
      name: 'orderType',
      required: true
    },
    {
      name: 'invoice',
      required: false
    },
    {
      name: 'orderGrouper',
      required: false
    },
    {
      name: 'identifier',
      required: true
    },
    {
      name: 'serviceType',
      required: false
    },
    {
      name: 'clientPickup',
      required: false
    },
    {
      name: 'receptor',
      required: false
    },
    {
      name: 'warehouse',
      required: false
    },
    {
      name: 'comments',
      required: false
    }
  ]
};
