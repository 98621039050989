<mat-card>
  <mat-card-content>
    <mat-grid-list cols="3" rowHeight="2rem">
      <mat-grid-tile colspan="3">
        <span class="dialog-title">
          <p> {{ labels.title }} </p>
        </span>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <mat-grid-list cols="5" rowHeight="3rem">
      <mat-grid-tile colspan="2">
        <mat-card>
          <mat-card-content>
            <mat-grid-list cols="5" rowHeight="4rem">
              <mat-icon class="icon-origin">
                <img class="img-class" src="{{ associateCarrierIcon }}" alt="associate Carrier Icon"/>
              </mat-icon>
            </mat-grid-list>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-card>
          <mat-card-content>
            <mat-grid-list cols="3" rowHeight="4rem">
              <form [formGroup]="assignCarrierFormGroup" *ngIf="carriers">
                <mat-grid-tile>
                  <mat-form-field>
                    <mat-label>{{ labels.carrierPlaceholder }}</mat-label>
                    <mat-select
                      id="carriersSelect"
                      multiple
                      required
                      formControlName="carrier">
                      <mat-option
                        id="selectAllOption"
                        #allCarriersSelected
                        class="select-all-option"
                        [value]="0"
                        (click)="selectAllCarriers()">
                        {{ labels.selectAll }}
                      </mat-option>
                      <mat-option
                        *ngFor="let carrier of carriers.lineasTransporte"
                        (click)=onCarrierSelected()
                        [id]="carrier"
                        [value]="carrier">{{ carrier.nombre }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      <strong>{{ labels.carrierRequired }}</strong>
                    </mat-error>
                  </mat-form-field>
                </mat-grid-tile>
              </form>
            </mat-grid-list>
            <mat-grid-list cols="3">
              <mat-grid-tile>
                <span>
                  <button
                    id="cancelButton"
                    mat-stroked-button
                    (click)="onClose()"
                    color="primary"
                    class="secondary-button-cancel">
                    {{ labels.cancel }}
                  </button>
                </span>
                <span>
                  <button
                    *ngIf="carriers"
                    id="saveButton"
                    [disabled]="!assignCarrierFormGroup.valid || saveButtonDisabled"
                    (click)="confirmAssignation()"
                    mat-raised-button
                    class="primary-button-accept">
                    {{ labels.save }}
                  </button>
                </span>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
