import { DialogData } from 'scf-library/lib/scf-lib/scf-dialog/scf-dialog.interface';
import { isEqual as _isEqual } from 'lodash';

import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DialogStandardFocusComponent } from '../../../../components/dialog/dialog-standard-focus/dialog-standard-focus.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';

import { CarrierBody, CarrierGroup } from '../../../../interfaces/carrier-group';
import { CarrierGroupProvider } from '../../../../../app/providers/carrier-group/carrier-group.provider.service';
import { CarrierNames, CarrierShipper } from './../../../../interfaces';
import { CarrierProvider } from '../../../../../app/providers/carrier/carrier.provider.service';
import { DialogAssignCarriersConstants } from '../dialog-assign-carriers/dialog-assign-carriers.constants';
import { DialogAssignCarriersLabels } from '../../dialog-marketplace/dialog-assign-carriers/dialog-assign-carriers.component.labels';
import { DialogAssignCarriersTags } from '../../../../interfaces/dialog-assign-carriers';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-assign-carriers',
  templateUrl: './dialog-assign-carriers.component.html',
  styleUrls: ['./dialog-assign-carriers.component.scss']
})
export class DialogAssignCarriersComponent implements OnInit {
  @ViewChild('allCarriersSelected') private allcarriersSelected: MatOption;
  @Input() public carrierGroupSelected: CarrierGroup;

  public assignCarrierFormGroup: UntypedFormGroup;
  public associateCarrierIcon: string;
  public carriers: CarrierShipper;
  public carriersAssignedToGroup: Array<CarrierNames>;
  public carriersToAssign: Array<CarrierBody>;
  public labels: DialogAssignCarriersTags;
  public saveButtonDisabled: boolean;
  public shipperId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private carrierProvider: CarrierProvider,
    private carrierGroupProvider: CarrierGroupProvider,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogAssignCarriersComponent>,
    private formBuilder: UntypedFormBuilder,
    private toastAlertService: ToastrAlertsService,
  ) {
    this.carrierGroupSelected = data as CarrierGroup;
    this.labels = DialogAssignCarriersLabels;
    this.associateCarrierIcon = DialogAssignCarriersConstants.ASSOCIATE_CARRIER_ICON;
    this.carriersToAssign = [];
    this.carriersAssignedToGroup = [];
    this.assignCarrierFormGroup = this.formBuilder.group({
      carrier: new UntypedFormControl(this.carriersAssignedToGroup, Validators.required),
    });
    this.assignCarrierFormGroup.valueChanges.subscribe(() => {
      this.validateChangesInForm();
    });
    this.saveButtonDisabled = true;
  }

  /**
   * Initialize form, get and sort information about carriers and checkf if the group has carriers assigned
   */
  public async ngOnInit(): Promise<void> {
    this.shipperId = this.carrierGroupSelected.shipperId;
    try {
      this.toastAlertService.processingAlert();
      this.carriers = await this.carrierProvider.getCarrierNamesByShipper(this.shipperId);
      this.carriers.lineasTransporte.sort((a, b) => (a.nombre.toLocaleLowerCase() > b.nombre.toLocaleLowerCase()) ? 1 :
      (( b.nombre.toLocaleLowerCase() > a.nombre.toLocaleLowerCase()) ? -1 : 0 ));
      this.getCarriersAssignedData();
    } catch (error) {
      this.toastAlertService.closeProcessing();
      this.toastAlertService.errorAlert(DialogAssignCarriersLabels.noCarriersFoundMessage);
    }
  }

  /**
   * @description check if all carriers are selected to mark as checked/unchecked the option "seleccionar todos"
   */
  public onCarrierSelected(): void {
    if (this.allcarriersSelected && this.allcarriersSelected.selected) {
      this.allcarriersSelected.deselect();
    }
    if (this.assignCarrierFormGroup.controls.carrier.value.length === this.carriers.lineasTransporte.length) {
      this.allcarriersSelected.select();
    }
  }

  /**
   * @description check if the carrier group selected has carriers assigned to display as checked that options in the select
   */
  private getCarriersAssignedData(): void {
    const carriersAssigned = this.carrierGroupSelected.carriers;
    if (carriersAssigned) {
      for (let i = 0; i < carriersAssigned.length; i++) {
        this.carriersAssignedToGroup.push(this.carriers.lineasTransporte.find(carrier => carrier.nombre === carriersAssigned[i].name));
      }
    }
    this.validateCarrierNumber();
    this.toastAlertService.closeProcessing();
  }

  /**
   * @description check if the carriers assigned to the group selected are the same as the carriers availables to mark
   * as checked the option "Seleccionar todos"
   */
  public validateCarrierNumber(): void {
    if (_isEqual(this.carriersAssignedToGroup, this.carriers.lineasTransporte)) {
      this.assignCarrierFormGroup.controls.carrier.value.push(0);
    }
  }

  /**
   * @description validates if data registered of a the carrier group have been modified to disable or enable the save button
   */
  public validateChangesInForm(): void {
    const carrierSelecteds = this.assignCarrierFormGroup.controls.carrier.value;
    if (_isEqual(this.carriersAssignedToGroup, carrierSelecteds)) {
      this.saveButtonDisabled = true;
    } else {
      this.saveButtonDisabled = false;
    }
  }

  /**
   * @description method to select all carriers avaliable and assign it to the form
   */
  public selectAllCarriers(): void {
    if (this.allcarriersSelected && this.allcarriersSelected.selected) {
      this.assignCarrierFormGroup.controls.carrier
        .patchValue([...this.carriers.lineasTransporte.map(item => item), 0]);
    } else {
      this.assignCarrierFormGroup.controls.carrier.patchValue([]);
    }
  }

  /**
   * @description displays a modal to confirm de assignation of carriers to the group
   */
  public confirmAssignation(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        title: DialogAssignCarriersLabels.modalTitle,
        iconPath: DialogAssignCarriersConstants.MODAL_ICON,
        resume: DialogAssignCarriersLabels.modalResume + this.carrierGroupSelected.name,
        question: DialogAssignCarriersLabels.modalQuestion,
        textButton1: DialogAssignCarriersLabels.cancel,
        textButton2: DialogAssignCarriersLabels.accept
      },
      width: DialogAssignCarriersConstants.MODAL_SIZE
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === DialogAssignCarriersConstants.CONFIRM) {
        this.createCarrierInfoToAssign();
      }
    });
  }

  /**
   * @description iterates all carriers selected to create an array of that carriers, also
   * checks if the value of "seleccionar todos" is checked to remove it and then saves the remaining info
   */
  private async createCarrierInfoToAssign(): Promise<void> {
    this.toastAlertService.processingAlert();
    this.assignCarrierFormGroup.controls.carrier.value.forEach((element, index) => {
      if (element === 0) {
        this.assignCarrierFormGroup.controls.carrier.value.splice(index, 1);
      }
    });
    for (let i = 0; i < this.assignCarrierFormGroup.value.carrier.length; i++) {
      const carrierInfo = {
        id: this.assignCarrierFormGroup.value.carrier[i]._id,
        name: this.assignCarrierFormGroup.value.carrier[i].nombre
      };
      this.carriersToAssign.push(carrierInfo);
      this.carrierGroupSelected.carriers = this.carriersToAssign;
    }
    try {
      await this.carrierGroupProvider.updateCarrierGroup(this.carrierGroupSelected, false);
      this.toastAlertService.closeProcessing();
      this.toastAlertService.successAlert(DialogAssignCarriersLabels.assignCarriersSuccess);
      this.onClose();
    } catch (error) {
      this.toastAlertService.closeProcessing();
      this.toastAlertService.errorAlert(DialogAssignCarriersLabels.assignCarriersError);
    }
  }

  /**
   * @description Close the modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }
}
